.marker-cluster-hospital{
    background-color: #a60508
}

.marker-cluster-hospital {
    background-clip: padding-box;
    border-radius: 20px; }
  
  .marker-cluster-hospital div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
    background-color: #fff;
  }
  .marker-cluster-hospital span {
    line-height: 30px; }

    .marker-cluster-pharmacy{
        background-color: #7d3636;
    }
    
    .marker-cluster-pharmacy {
        background-clip: padding-box;
        border-radius: 20px; }
      
      .marker-cluster-pharmacy div {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-top: 5px;
        text-align: center;
        border-radius: 15px;
        font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
        background-color: #f76e6e;
      }
      .marker-cluster-pharmacy span {
        line-height: 30px; }

        .marker-cluster-doctor{
          background-color: #cb361d;
      }

        .marker-cluster-doctor {
          background-clip: padding-box;
          border-radius: 20px; }
        
        .marker-cluster-doctor div {
          width: 30px;
          height: 30px;
          margin-left: 5px;
          margin-top: 5px;
          text-align: center;
          border-radius: 15px;
          font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
          background-color: #ff3a1c;
        }
        .marker-cluster-doctor span {
          line-height: 30px; }
