.leaflet-container{
    background-color: white;
}

.infoBox {
    height: calc(45vh - 28px);
    margin: 10px;
    overflow-y: auto;
    -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.77); 
    box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.77);
}

.leaflet-popup-content-wrapper {
    max-height: 50vh;
    font-size: 1rem;
    overflow: auto;
}

.leaflet-popup-close-button {
    margin-right: 20px;
    font-size: 2rem !important;
}

div.leaflet-control-attribution {
    visibility: hidden;
}

.marker-cluster-disease{
    background-color: #ffff0099;
}

.marker-cluster-disease {
    background-clip: padding-box;
    border-radius: 20px; }
  
  .marker-cluster-disease div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
    background-color: #ffff00aa;
  }
  .marker-cluster-disease span {
    line-height: 30px; }

    .marker-cluster-bodyRegion{
        background-color: #4d94ff99;
    }
    
    .marker-cluster-bodyRegion {
        background-clip: padding-box;
        border-radius: 20px; }
      
      .marker-cluster-bodyRegion div {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-top: 5px;
        text-align: center;
        border-radius: 15px;
        font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
        background-color: #4d94ffaa;
      }
      .marker-cluster-bodyRegion span {
        line-height: 30px; }

        .marker-cluster-diseaseEtiology{
            background-color: #ffff0099;
        }
        
          
          .marker-cluster-diseaseEtiology div {
            width: 30px;
            height: 30px;
            margin-left: 5px;
            margin-top: 5px;
            text-align: center;
            font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
            background-color: #ffff00aa;
          }
          .marker-cluster-diseaseEtiology span {
            line-height: 30px; }

            .bodySchemaTitle {
              background-color: #fff;
              margin-bottom: 0px;
              text-align: center;
            }
            .bodySchemaTitle h3 {
              margin: 0;
            }
