.spinner {
  font-size: 120px;
  color: #85ce36;
  position: absolute;
  top: calc(50% - 25px);
  left: 50%;
  margin-top: -60px;
  margin-left: -60px;
  z-index: 1000;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  background: #aaaaaa99;
  width: 100vw;
  height: 100vh;
  z-index: 5000;
}

.weißerKasten {
  background-color: white;
  width: 150px;
  height: 150px;
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  border: 2px solid black;
  -webkit-box-shadow: 5px 5px 15px 7px #000000; 
  box-shadow: 5px 5px 15px 7px #000000;
}