.header {
    height: 60px;
    background-color: #9d4349;
    color: white;
    font-size: 1.5rem;
    padding: 10px 30px;
    width: 100vw;
}

#graph {
    height: calc(100vh - 70px);
    width: 100vw;
}

div.popupMenu {
    position:absolute;
    top:0px;
    left:0px;
    width:200px;
    height:300px;
    border: 1px solid black;
    -webkit-box-shadow: 5px 5px 10px 0px #CCC;
    -moz-box-shadow: 5px 5px 10px 0px #CCC;
    box-shadow: 5px 5px 10px 0px #CCC;
    background-color:white;
  }

.content {
    width: 100vw;
    height: calc(100vh - 56px);
    background-color: #f5f5f5;
}
#mapContainer {
    height: 500px;
}

.navbar a {
    color: black !important;
}



.navbar a:hover {
    color: #666 !important;
}

.btn-link {
    color: black !important;
}

.btn-link:hover {
    color: #666 !important;
}

.navbar {
    background-color: #bbbec3;
    padding: 0.1rem 1rem;
}

.navIcon {
    font-size: 2rem;
    margin: 2px 30px;
}

.navIcon:hover {
    cursor: pointer;
}

.resultBubble {
        width: 30px;
        height: 30px;
        margin-left: 20px;
        margin-top: 10px;
        text-align: center;
        border-radius: 15px;
        font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.Facilities {
    background-color: #f53d3d;
    border: 2px solid #f20d0d;
}

.Diseases {
    background-color: #ffff00;
    border: 2px solid #e6e600;
}

.Regions {
    background-color: #99cc00;
    border: 2px solid #739900;
}

.Anatomy {
    background-color: #4d94ff;
    border: 2px solid #1a75ff;
}

.resultBubble span{
    line-height: 30px;
}
